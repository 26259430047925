<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.partner") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="card p-4">
        <div
          class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
        >
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ partner.name }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.licence_validity") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{
                getLocaleDate(locale, partner.licenceValidity)
              }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.type") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else-if="partner.types">{{
                partner.types
                  .map((partnerType) =>
                    t("partner_types." + getTypeName(partnerType, types))
                  )
                  .join(", ")
              }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.email") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ partner.email }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.phone") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ partner.phone }}</span>
            </div>
          </div>
        </div>
        <hr class="my-4 border-slate-300" />
        <div class="flex justify-end">
          <form-button
            :command="() => router.push(`/partners/${partner.id}/edit`)"
            :disabled="waiting"
            id="profile-edit-button"
            label="edit"
            textVariant="white"
            type="button"
            variant="indigo"
          />
        </div>
      </div>
      <div>
        <h2 class="mb-2">{{ t("headings.estates") }}</h2>
        <div class="card">
          <custom-table
            :fields="estateFields"
            :items="estates"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="estateSearchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="estateSortFields"
            @row-click=""
          >
          </custom-table>
        </div>
      </div>
      <div>
        <h2 class="mb-2">{{ t("headings.personnel") }}</h2>
        <div class="card">
          <custom-table
            :fields="userFields"
            :items="users"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="userSearchFields"
            :showHeader="true"
            sortBy="lastName"
            :sortFields="userSortFields"
            @row-click="
              (id) => router.push(`/partners/${partner.id}/personnel/${id}`)
            "
          >
            <template #header>
              <span class="flex justify-end"
                ><form-button
                  :command="
                    () =>
                      router.push(`/partners/${partner.id}/personnel/create`)
                  "
                  id="header-user-create-button"
                  label="create_user"
                  textVariant="white"
                  type="button"
                  variant="indigo"
              /></span>
            </template>
            <template #cell(lastName)="{ item }">
              <div class="flex items-center">
                <img
                  v-if="item.avatar"
                  class="h-10 w-10 rounded-full object-cover"
                  :src="getAvatar(item)"
                />
                <div
                  v-else
                  class="aspect-square flex h-10 w-10 items-center justify-center rounded-full bg-slate-300"
                >
                  <UserIcon class="h-3/4 w-3/4 text-white" />
                </div>
                <span class="ml-5"
                  >{{ item.lastName }} {{ item.firstName }}</span
                >
              </div>
            </template>
            <template #cell(roleId)="{ value }">
              {{ t("roles." + getRole(value)) }}
            </template>
            <template #cell(status)="{ value }">
              {{ t(`status.${value}`) }}
            </template>
          </custom-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getAvatar } from "../utils/imageUtils";
import { getTypeName } from "../utils/typeUtils";

export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton, UserIcon },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const estates = computed(() => store.state.estate.estates);
    const partner = computed(() => store.state.partner.partner);
    const roles = computed(() => store.state.role.roles);
    const types = computed(() => store.state.partner.types);
    const users = computed(() => store.state.user.users);

    const estateFields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "companyName",
        label: "company",
      },
      {
        key: "address",
        label: "address",
      },
      {
        key: "postalCode",
        label: "postal_code",
      },
      {
        key: "city",
        label: "city",
      },
    ]);
    const estateSearchFields = ref([
      "name",
      "companyName",
      "address",
      "postalCode",
      "city",
    ]);
    const estateSortFields = ref([
      "address",
      "city",
      "companyName",
      "name",
      "postalCode",
    ]);

    const userFields = ref([
      {
        key: "lastName",
        label: "name",
      },
      {
        key: "roleId",
        label: "role",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "phone",
        label: "phone",
      },
      {
        key: "status",
        label: "status",
      },
    ]);

    const userSearchFields = ref(["email", "firstName", "lastName", "phone"]);

    const userSortFields = ref(["email", "lastName"]);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.partner.waiting) return true;
      if (store.state.role.waiting) return true;
      return false;
    });

    const getRole = (roleId) => {
      const role = roles.value.find((role) => role.id == roleId);
      return role ? role.label : null;
    };

    onMounted(() => {
      if (!partner.value || partner.value.id != route.params.partnerId) {
        store.dispatch("partner/getPartner", route.params.partnerId);
      } else {
        store.dispatch("estate/getPartnerEstates", partner.value.id);
      }
      if (!types.value.length) {
        store.dispatch("partner/getTypes");
      }
      if (!roles.value.length) {
        store.dispatch("role/getRoles");
      }
    });

    watch(
      () => partner.value,
      (partner) => {
        if (partner.id) {
          store.dispatch("estate/getPartnerEstates", partner.id);
          store.dispatch("user/getCompanyUsers", partner.id);
        }
      }
    );

    return {
      estates,
      estateFields,
      estateSearchFields,
      estateSortFields,
      getAvatar,
      getLocaleDate,
      getRole,
      getTypeName,
      locale,
      partner,
      route,
      router,
      userFields,
      users,
      userSearchFields,
      userSortFields,
      t,
      types,
      waiting,
    };
  },
};
</script>
